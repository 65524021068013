.root {

  & path {
    fill: #DFFF60;
  }
}

.disabled {

  & path {
    fill: #fff;
  }
}
