@import '../../styles/customMediaQueries.css';

.cropContainer {
  text-align: center;
  margin-bottom: 20px;

  /* & :global(.ReactCrop__crop-selection) {
    box-shadow: none;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      
      z-index: 1;
      backdrop-filter: blur(10px);
      background: rgba(0, 0, 0, 0.5);

      width: 1000%;
      min-width: 1000%;
      height: 1000%;

      transform: translateY(-50%);
    }

    &:after {
      right: 100%;
      left: auto;
    }
  }

  & :global(.ReactCrop__drag-elements) {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      
      z-index: 1;
      backdrop-filter: blur(10px);
      background: rgba(0, 0, 0, 0.5);

      width: 1000%;
      min-width: 1000%;
      height: 1000%;
    }

    &:after {
      right: auto;
      left: 0;
      bottom: 100%;
      top: auto;
    }
  } */
}

.cropTitle {
  padding: 0;
  margin: 0 0 12px;
  font-size: 38px;
  text-align: left;
}

.cropDescription {
  padding: 0;
  margin: 0 0 44px;
  font-weight: var(--fontWeightRegular);
  text-align: left;
}

.buttons {
  display: flex;
  justify-content: space-between;
  
  & .btnWhite,
  & .btnDefault {
    max-width: 200px;
  }

  & .btnWhite {
    background-color: var(--colorWhite);
    border-radius: 50px;
  }
}

.rangeContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.customRange {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: #D6DEDC;
  outline: none;
  border-radius: 5px;
  padding: 0;
  cursor: pointer;
  border: none;
  
  &:hover,
  &:focus {
    background: #D6DEDC;
    border: none;
  }
}

.customRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 26px;
  border-radius: 20px;
  background: #335A50;
  cursor: pointer;
}

.customRange::-moz-range-thumb {
  width: 12px;
  height: 26px;
  border-radius: 20px;
  background: #335A50;
  cursor: pointer;
}

.rangeIconSmall {
  margin-right: 12px;

  & svg {
    width: 16px;
    height: 16px;
  }
}

.rangeIconBig {
  margin-left: 12px;
}

