@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  border-radius: 12px;
  box-shadow: 1px 1px 4px 0px rgba(51, 90, 80, 0.55);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    transition: var(--transitionStyleButton);
  }
}

.rootLink {
  /* Layout */
  display: flex;
  flex-direction: column-reverse;
  height: 100%;

  @media (--viewportLarge) {
    flex-direction: row;
  }

  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  width: 100%;
  background: var(--colorGrey100); /* Loading BG color */
  overflow: hidden;
  border-radius: 12px 12px 0 0;

  @media (--viewportLarge) {
    width: 114px;
    border-radius: 0 12px 12px 0;
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.mainInfo {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  width: 100%;

  padding: 18px 12px;
  border-radius: 12px 0 0 12px;
  color: var(--marketplaceSecondaryColor);

  @media (--viewportLarge) {
    width: calc(100% - 114px);
  }
}

.title {
  /* Font */
  margin: 0 0 10px;
  font-size: 22px;
  font-family: var(--fontFamily);
  line-height: 28px;
  font-weight: bold;
}

.offerLocation {
  align-items: center;
  margin: 0 0 10px;
  font-family: var(--buttonFontFamily);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  border-radius: 8px;
  background: #DDFFED;
  display: inline-block;
  padding: 3px 7px 3px;

  & svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  &.offerLocationInPerson {
    background-color: #DDFFED;
  }
  
  &.offerLocationOnline {
    background-color: #CBEFF7;
  }

  &.offerLocationClientLocation {
    background-color: #F7E4C8;
  }
}

.offerIntroduction {
  font-family: 'Krub';
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.5px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.holisticHealthFocus {
  margin: 0 0 20px;
  padding: 0;
}

.holisticHealthFocusItem {
  display: inline-block;
  font-size: 10px;
  line-height: 18px;
  padding: 0 5px;
  margin: 0 5px 5px 0;
  border-radius: 20px;
  background-color: var(--marketplaceLightGreen);
}

.priceHolder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.3;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  font-family: var(--fontFamily);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.5px;

  @media (--viewport1440) {
    font-size: 22px;
  }
}

.priceValue {

}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceGreen);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.duration {
  font-family: var(--fontFamily);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.5px;

  @media (--viewport1440) {
    font-size: 22px;
  }
}

.durationRange {
  font-weight: 400;
}

.actionButtons {
  display: flex;
  align-items: center;

  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
}

.actionButtonsItem {
  margin-left: 10px;
  cursor: pointer;
}

.socialShare {

}

.addToFavorites {

}
