.icon {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  margin-right: 8px;
}

.selectError {
  border: var(--borderErrorField);
}

.root {
  display: inline-block;
  min-width: fit-content;
}

.dropdownContent {
  display: block;
  position: absolute;
  min-width: fit-content;
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusMedium);
  border: 1px solid #d8dce6;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;
  max-height: 200px;
  scrollbar-width: thin;
  scrollbar-color: #cccccc #ffffff;
}

.dropdownButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--borderRadiusFull);
  border: 1px solid #d8dce6;
  cursor: pointer;
  padding: 7px 16px;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  gap: 12px;

  &:hover {
    border: 1px solid #b8bfd1;
    transition: all ease-in-out 100ms;
  }
}

.arrow {
  transition: transform 0.3s ease-in-out;
}

.arrowRotated {
  transition: transform 0.3s ease-in-out;
  transform: rotate(180deg);
}
