@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }

  & input {
    height: 32px;
  }

  & label {
    margin: 0 0 13px;
    padding: 0;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.5px;
    font-weight: var(--fontWeightSemiBold);
  }
}

.field {
  width: 100%;
  margin-top: 22px;
  
  & input {
    height: 32px;
  }
}

.postalCodeCityHolder {
  display: flex;
  justify-content: space-between;
}

.fieldPostalCode {
  width: 100%;
  max-width: 150px;
}

.fieldCity {
  width: 100%;
  max-width: calc(100% - 162px);
}

.countryHolder {

}
