@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  margin: 0;
  letter-spacing: -0.5px;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemBlock {
  display: block;
  margin: 0;
  letter-spacing: -0.5px;

  & .lineItem {
    padding: 0 0 2px;
    margin: 0;
  }
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 2px;
}

.bookingPeriodSectionLeft {
  text-align: left;
}

.bookingPeriodSectionRight {
  text-align: right;
}

.label {
  line-height: 22px;
  font-weight: var(--fontWeightSemiBold);

  padding: 0 0 4px;
  margin: 0;
}

.dayInfo,
.locationValue,
.totalPrice {
  composes: p from global;

  margin: 0;
  padding: 0;
  font-weight: var(--fontWeightRegular);
}

.locationValue {
  padding-bottom: 2px;
}

.dayInfo {
  line-height: 30px;
}

.totalPrice {
  font-weight: var(--fontWeightSemiBold);
}

.subTotalLineItem {
  composes: p from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 7px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  composes: p from global;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  font-weight: var(--fontWeightSemiBold);
}

.itemLabel {
  font-weight: var(--fontWeightRegular);
  flex: 1;
}

.itemValue {
  font-weight: var(--fontWeightRegular);
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: #859C96;

  @media (--viewportMedium) {
    margin: 0 0 22px 0;
  }
}

.itemValueFee {

  &::first-letter {
    padding-right: 4px;
  }
}

.totalLabel {
  font-weight: var(--fontWeightSemiBold);
}

.feeInfo {
  composes: marketplaceTinyFontStyles from global;
  flex-shrink: 0;
  margin: 0;
  color: var(--colorGrey300);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.locationLabel {
  composes: textSmall from global;
  margin: 0;
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding: 0;
  }
}