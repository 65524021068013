.root {
}

.input {
  height: 36px;
  border: var(--borderDefaultField);
  color: var(--colorDefaultGray);

  &:active,
  &:focus {
    border: var(--borderSuccessField);
  }

  &:disabled {
    border: var(--borderDisabledField);
    background: var(--colorLighttGray);
    color: var(--colorButtonBorderDisabled);
  }
}
.textarea {
  min-height: 135px;
  border: var(--borderDefaultField);
  color: var(--colorDefaultGray);

  &:active,
  &:focus {
    border: var(--borderSuccessField);
  }

  &:disabled {
    border: var(--borderDisabledField);
    background: var(--colorLighttGray);
    color: var(--colorButtonBorderDisabled);
  }

}

.inputSuccess {
  border: var(--borderSuccessField);
}
.inputError {
  border: var(--borderErrorField);
}
