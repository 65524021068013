@import '../../styles/customMediaQueries.css';

.treatmentPanel {
  padding: 60px 24px 0;

  @media (--viewportMedium) {
    padding: 0;
  }

  & .modalContainer {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  & .headingHolder {
    display: flex;
    flex-direction: column;
  }

  & .headingInfoHolder {
    display: flex;
    align-items: center;
  }

  .titleHolder {
    display: flex;
    justify-content: space-between;
  }

  & .heading,
  & .price {
    color: var(--marketplaceSecondaryColor);
    font-size: 38px;
    line-height: 1.15;
  }

  & .perUnit {
    display: none;
  }

  & .headingDescription {
    padding: 0;
    margin: 12px 0;
    font-size: 14px;
    line-height: 1.4;
    color: #4A4A4A;
  }

  & .offerLocation,
  & .offerIntroduction {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.2;
    font-weight: var(--fontWeightRegular);

    & svg {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }

  .offerLocation {
    align-items: center;
    margin: 0 15px 0 0;
    font-family: var(--buttonFontFamily);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.5px;
    border-radius: 8px;
    background: #DDFFED;
    display: inline-block;
    padding: 3px 7px 3px;
  
    & svg {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  
    &.offerLocationInPerson {
      background-color: #DDFFED;
    }
    
    &.offerLocationOnline {
      background-color: #CBEFF7;
    }
  
    &.offerLocationClientLocation {
      background-color: #F7E4C8;
    }
  }

  & .offerDuration {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    font-weight: var(--fontWeightSemiBold);

    & svg {
      margin-right: 4px;
    }
  }

  & .openOrderForm {
      position: static;
      width: 100%;
      box-shadow: none;
      border: none;
      padding: 0;
      background-color: transparent;
      
      @media (--viewportMedium) {
        margin-top: 40px;
      }

      & button {
        max-width: 309px;
        min-height: 46px;
        background-color: var(--marketplaceGreen);
        border-radius: 50px;
        color: var(--colorWhite);
        font-weight: var(--fontWeightLight);

        &:hover,
        &:focus {
          background-color: var(--marketplaceColorDark);
        }
    }
  }

  & .bookingForm {
    margin-top: 34px;

    & > label {
      
      @media (--viewportMedium) {
        font-size: 22px;
      }
    }
  }

}

.profilePanel {

}


/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  /* @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  } */
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 15px;

  @media (--viewportMedium) {

    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceGreen);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }

  &::first-letter {
    letter-spacing: 10px;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceGreen);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.author {
  composes: p from global;
  margin: 0px 24px 32px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.openOrderFormDesktopView {
    /* Ensure that mobile button is over Footer too */
  /* z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw; */
  /* padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop); */
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  /* transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  } */
/*
  @media (--viewportLarge) {
    display: none;
  } */

  & button {
    max-width: 309px;
    min-height: 46px;
    background-color: var(--marketplaceGreen);
    border-radius: 50px;
    color: var(--colorWhite);
    font-weight: var(--fontWeightLight);

    &:hover,
    &:focus {
      background-color: var(--marketplaceColorDark);
    }
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceGreen);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.primaryButton {
  composes: buttonPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;

  max-width: 309px;
  min-height: 46px;
  background-color: var(--marketplaceGreen);
  border-radius: 50px;
  color: var(--colorWhite);
  font-weight: var(--fontWeightLight);
  display: flex;
  align-items: center;
  justify-content: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
