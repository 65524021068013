.comingSoon {
  margin-top: 60px;
}

.comingSoonTitle {
  padding: 0;
  margin: 0 0 10px;
  font-size: 28px;
}

.comingSoonDescription {
  padding: 0;
  margin: 0;
  font-weight: var(--fontWeightRegular);
}

.comingSoonIcon {
  fill: none;
}
