@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding:0;
  border: none;

  @media (--viewportMedium) {
    padding: 0
  }
}

.list {
  margin: 0;
  columns: 2;

  @media (--viewportMedium) {
    columns: 1;
  }
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.optionsForm {
  width: auto;
  position: relative;
  margin-top: 5px;
  
  @media (--viewportMedium) {
    max-height: 36px;
    margin-top: 0;
  }
}

.optionsInput {
  width: 100%;
  color: #7B8084;
  margin: 20px 0;

  /*-webkit-text-security: disc;*/

  &::placeholder {
    color: #7B8084;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.toggler {
  cursor: pointer;
  font-size: 16px;
  padding: 10px 0 4px 12px;
  display: flex;
  color: var(--marketplaceColor);
}

.dropdown {
  margin-top: 7px;
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusMedium);
  transition: var(--transitionStyleButton);
  width: 100%;
  padding: 6px 12px;
  
  @media (--viewportMedium) {
    position: absolute;
    top: 36px;
    left: 0;
    z-index: 999;
    padding: 0 30px 20px 30px;
    
    border-top: 1px solid var(--colorGrey100);
    box-shadow: var(--boxShadowPopup);
    max-height: 300px;
    overflow: auto;
  }
}

.inputHolder {
  position: relative;
}

.iconSearch {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.buttonsWrapper {
  display: none;
  padding: 16px 0;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
  
  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
  }
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--linksColor);

  /* Layout */
  margin: 0 0 0 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceDarkGreen);
  }
}

