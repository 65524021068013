.root {
  position: relative;
  display: inline-block;
  width: 100%;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--colorWhite);

  /* Borders */
  border-top: 1px solid var(--colorGrey100);
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--borderRadiusMedium);
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 15px 30px 17px 30px;
  max-height: 300px;
  overflow: auto;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.iconDown {
  margin-left: 8px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #7B8084;
  border-radius: 30px;
  color: #7B8084;
  height: 36px;
  font-family: var(--fontFamily);
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.5px;
  width: 100%;
  text-align: left;
  position: relative;
}

.upperLabel {
  margin-top: 20px;
}
